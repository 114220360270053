window.GoogleMap = () => {
	return {
		init() {
			(g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
				key: "AIzaSyCU3IGt_7FKXyfsYcMjh7a9nOmKQPcVwcU",
				v: "weekly",
				// Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
				// Add other bootstrap parameters as needed, using camel case.
			  });

			  let map;

			  async function initMap() {
				const midpoint = {lat: 37.516439, lng: -78.709521};
				const roanoke = {lat: 37.24991989135742, lng: -79.94821166992188};
				const richmond = {lat: 37.54776382446289, lng: -77.46589660644531};
				const { Map } = await google.maps.importLibrary("maps");
				const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

				map = new Map(document.getElementById("google-map-block"), {
					zoom: 9,
					center: midpoint,
					mapId: "pdg-google-map",
					disableDefaultUI: true,
				});

				const richmondInfo = new google.maps.InfoWindow({
					content: `<div class="p-2 max-w-[250px] "><p class="!font-bold text-base md:text-[20px] !m-0 leading-1">Parker Design Group</p><a class="no-underline hover:underline" href="https://www.google.com/maps/dir/My+Location/1915+W+Cary+St+%23+B,+Richmond,+VA+23220/@37.6910277,-79.3797416,9z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x884d0db135577887:0x3a9e04cedeb4cd4a!2m2!1d-79.9556424!2d37.2724943!1m5!1m1!1s0x89b111593f87e3b7:0x5f503e734d6191c6!2m2!1d-77.4659037!2d37.5477628?entry=ttu" target="_blank"><p class="!m-0 text-base md:text-[20px] leading-1">1915-B W. Cary Street</p><p class="!m-0 text-base md:text-[20px] leading-1">Richmond, Virginia 23220</p></a><p class="!m-0 text-base md:text-[20px] leading-1">Phone: <a class="no-underline hover:underline font-normal" href="tel:8043582947">804.358.2947</a></p></div>`,
				});

				const roanokeInfo = new google.maps.InfoWindow({
					content: `<div class="p-2 max-w-[250px]"><p class="!font-bold !m-0 leading-1 text-base md:text-[20px]">Parker Design Group</p><a class="no-underline hover:underline" href="https://www.google.com/maps/dir/My+Location/2122+Carolina+Ave+SW,+Roanoke,+VA+24014/@37.2615113,-79.9657466,15z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x884d0db135577887:0x3a9e04cedeb4cd4a!2m2!1d-79.9556424!2d37.2724943!1m5!1m1!1s0x884d0d096e10ed11:0x637d077a563f70d3!2m2!1d-79.9482211!2d37.2499454?entry=ttu" target="_blank"><p class="!m-0 leading-1 text-base md:text-[20px]">2122 Carolina Avenue, SW</p><p class="!m-0 text-base md:text-[20px] leading-1">Roanoke, Virginia 24014</p></a><p class="!m-0 text-base md:text-[20px] leading-1">Phone: <a class="no-underline hover:underline font-normal" href="tel:5403871153">540.387.1153</a></p></div>`,
				});

				const richmondMarker = new AdvancedMarkerElement({
					map: map,
					position: richmond,
					title: "Richmond Office",
					
				});

				// richmondMarker.addListener("click", () => {
				// 	richmondInfo.open(map, richmondMarker);
				// });

				const roanokeMarker = new AdvancedMarkerElement({
					map: map,
					position: roanoke,
					title: "Roanoke Office"
				});

				// roanokeMarker.addListener("click", () => {
				// 	roanokeInfo.open(map, roanokeMarker);
				// });

				roanokeInfo.open(map, roanokeMarker);
				richmondInfo.open(map, richmondMarker);
			  }

			  initMap();
		}
	};
};