window.ProjectList = (blockFields, totalPages, projectsExist) => {
	return {
		projectsExist: projectsExist || false,
		currentPage: 1,
		totalPages: totalPages || 1,
		hasMultiplePages: totalPages > 1,
		featuredProjectName: '',
		fetchAttempts: 0,
		query: {
			category: '',
			service: '',
			projectManager: '',
		},
		init() {
			// get the featured project name
			if(document.querySelector('[id^="featured-project-block"] h3')) {
				this.featuredProjectName = document.querySelector('[id^="featured-project-block"] h3').innerHTML;
			}
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			// add query params to query object
			this.query.category = urlParams.get('category') || '';
			this.query.service = urlParams.get('service') || '';
			this.query.projectManager = urlParams.get('projectManager') || '';
			if(blockFields.filter_by !== 'all') {
				this.query.category = blockFields.project_category;
				this.query.service = blockFields.project_service;
			}
			this.$watch( 'query', () => {
				if ( this.currentPage !== 1 ) {
					this.currentPage = 1;
				}
				// update the url with the projectManager query
				const params = new URLSearchParams(this.query.projectManager ? { projectManager: this.query.projectManager } : '');
				window.history.pushState({}, '', '?' + params.toString());

				this.fetchProjects();

			}, { deep: true } );
		},
		fetchProjects() {
			const data = {
				action: 'get_projects',
				page: this.currentPage,
				category: this.query.category || null,
				service: this.query.service || null,
				projectManager: this.query.projectManager || null,
			};
			fetch(ajax.url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: new URLSearchParams(data).toString(),
			})
				.then(response => response.json())
				.then(data => {
					this.currentPage = data.pagination.current;
					this.totalPages = data.pagination.total;
					this.projectsExist = data.pagination.total > 0;
					this.hasMultiplePages = data.pagination.total > 1;
					// console.log(data);
					// data.list is compiled html for the next projectList
					this.$refs.projectList.innerHTML = data.list;
				})
				.catch(error => {
					console.error('There was an error fetching the projects, trying again:', error);
					// try again
					this.fetchAttempts++;
					if (this.fetchAttempts < 3) {
						console.log('Attempting to fetch projects again');
						this.fetchProjects();
					}
				});
		},


		goToPage(page) {
			this.currentPage = page;
			this.fetchProjects();
			// scroll to $refs.projectList
			this.$refs.projectList.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		},
	};
};
